{
  "name": "npmgraph",
  "type": "module",
  "version": "3.1.1",
  "description": "Visualize npm dependency graphs (public and private!)",
  "author": {
    "name": "Robert Kieffer",
    "url": "http://github.com/broofa",
    "email": "robert@broofa.com"
  },
  "license": "MIT",
  "browserslist": [
    "last 2 versions"
  ],
  "funding": [
    "https://github.com/sponsors/broofa",
    "https://github.com/sponsors/fregante"
  ],
  "repository": {
    "url": "https://github.com/npmgraph/npmgraph.git",
    "type": "git"
  },
  "keywords": [
    "npm",
    "dependencies",
    "dependency",
    "graph",
    "module"
  ],
  "scripts": {
    "build": "parcel build index.html --no-cache",
    "fetch-licenses": "node scripts/fetch_licenses.js",
    "release": "standard-version",
    "start": "parcel serve index.html --no-cache",
    "lint": "eslint .",
    "fix": "run-p 'lint -- --fix' format:fix",
    "format:check": "prettier -c .",
    "format:fix": "prettier -w .",
    "types": "tsc --noEmit",
    "test": "run-p format:check lint types build"
  },
  "dependencies": {
    "@bugsnag/js": "7.25.0",
    "@hpcc-js/wasm-graphviz": "^1.0.3",
    "@types/d3-interpolate": "3.0.4",
    "@types/d3-scale": "4.0.8",
    "@types/d3-scale-chromatic": "3.0.3",
    "@types/d3-shape": "3.1.6",
    "d3-hierarchy": "3.1.2",
    "d3-interpolate": "3.0.1",
    "d3-scale": "4.0.2",
    "d3-scale-chromatic": "3.1.0",
    "d3-selection": "3.0.0",
    "d3-shape": "3.2.0",
    "filter-altered-clicks": "2.1.1",
    "md5": "2.3.0",
    "react": "18.3.1",
    "react-dom": "18.3.1",
    "select-dom": "^9.0.0",
    "semver": "7.6.2",
    "simplur": "4.0.0"
  },
  "@parcel/resolver-default": {
    "packageExports": true,
    "packageExports-why": "added this because new graphviz uses exports in package.json"
  },
  "devDependencies": {
    "@antfu/eslint-config": "^2.24.0",
    "@eslint-react/eslint-plugin": "^1.7.1",
    "@npm/types": "2.0.0",
    "@parcel/runtime-react-refresh": "^2.12.0",
    "@parcel/transformer-inline-string": "2.12.0",
    "@parcel/transformer-sass": "^2.12.0",
    "@parcel/transformer-svg-react": "^2.12.0",
    "@types/d3-hierarchy": "3.1.7",
    "@types/d3-selection": "3.0.10",
    "@types/md5": "2.3.5",
    "@types/react": "18.3.3",
    "@types/react-dom": "18.3.0",
    "@types/semver": "^7.5.8",
    "eslint": "^9.8.0",
    "eslint-plugin-react-hooks": "^5.1.0-beta-26f2496093-20240514",
    "eslint-plugin-react-refresh": "^0.4.9",
    "npm-run-all2": "^6.2.2",
    "parcel": "^2.12.0",
    "postcss": "8.4.39",
    "postcss-modules": "4.3.1",
    "prettier": "3.3.3",
    "prettier-plugin-css-order": "2.1.2",
    "process": "0.11.10",
    "standard-version": "9.5.0",
    "typed-query-selector": "^2.11.2",
    "typescript": "5.5.3",
    "typescript-plugin-css-modules": "^5.1.0"
  }
}
