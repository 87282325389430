.root {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-block-start: 0.4rem;
  margin-block-end: 0.4rem;
  line-height: 1.5rem;
}

.maintainer {
  display: flex;
  flex: 0 0 17ch;
  align-items: center;
  gap: 0.3rem;
  min-width: 0;
  white-space: nowrap;

  > span {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > img {
    flex-grow: 0;
    margin-inline-start: 0.5rem;
    border-radius: 0.3rem;
    width: 32px;
    height: 32px;
  }
}

.modules {
  display: flex;
  flex-direction: column;
  min-width: 0;

  font-size: 0.9rem;
  font-family: monospace;

  :global {
    .selectable {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
