import licenses from './licenses_autogenerated.json';

export const LICENSES = licenses as Record<string, License>;

export type OSIKeyword =
  | 'osi-approved'
  | 'discouraged'
  | 'non-reusable'
  | 'redundant'
  | 'popular'
  | 'obsolete'
  | 'copyleft'
  | 'special-purpose'
  | 'permissive'
  | 'miscellaneous'
  | 'retired';

export type License = {
  // From SPDX list
  id: string;
  name: string;
  isOsiApproved?: true;
  isDeprecatedLicenseId?: true;

  // From OSI list
  keywords?: OSIKeyword[];
};
