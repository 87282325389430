.root {
  display: grid;
  position: absolute;
  align-content: center;
  cursor: default;
  box-sizing: border-box;
  border-radius: var(--rad_sm);
  background-color: var(--bg1);
  overflow: hidden;
  text-align: center;

  & > span {
    color: var(--bg0);
  }
}
