.root {
  --selectable: #840;
  cursor: default;
  color: var(--selectable);
  text-wrap: nowrap;

  @media (prefers-color-scheme: dark) {
    --selectable: #fc9;
  }
}
