.root svg {
  fill: var(--accent);
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  color: #fff;
}
.arm {
  transform-origin: 130px 106px;
}
.root:hover .arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .root:hover .arm {
    animation: none;
  }
  .root .arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}
