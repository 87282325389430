.row {
  display: flex;
  gap: 1rem;
  line-height: 1.5rem;
}

.name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.version {
  flex-grow: 0;
  font-size: 0.9rem;
  font-family: monospace;
}
