.row {
  display: flex;
  line-height: 1.5rem;
}

.name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entry {
  font-weight: bold;
}
